import React from 'react';
import { useRouter } from 'next/router';

import { BackButtonWrapper } from './BackButton.style';
import { useStores } from 'hooks/useStores';
import { observer } from 'mobx-react';
import { Map, ArrowLeftRound } from 'components/AllSvgIcon';

const BackButton = observer(({ action = null, goHome = false }) => {
  const router = useRouter();
  const { restaurant } = router.query;
  const { cartStore } = useStores();

  // React.useEffect(() => {
  //   // console.log('BACK BTN:', title,  goHome)
  // }, []);

  const goToHome = () => {
    if (cartStore.currentOutlet && cartStore.currentOutlet.url) {
      if (cartStore.currentRestaurant.domain)
        router.push(`/${cartStore.orderType}/${cartStore.currentOutlet.url}`);
      else
        router.push(
          `/[restaurant]/[orderType]/[store]`,
          `/${cartStore.currentRestaurant.url}/${cartStore.orderType}/${cartStore.currentOutlet.url}`,
          {
            shallow: true
          }
        );
    } else {
      if (cartStore.currentRestaurant.domain) router.push(`/`);
      else
        router.push(`/[restaurant]`, `/${cartStore.currentRestaurant.url}`, {
          shallow: true
        });
    }
  };

  const goBack = () => {
    if (
      cartStore.currentRestaurant &&
      cartStore.currentRestaurant.url &&
      cartStore.currentOutlet &&
      cartStore.currentOutlet.url
    ) {
      if (cartStore.currentRestaurant.domain)
        router.push(`/${cartStore.orderType}/${cartStore.currentOutlet.url}`);
      else
        router.push(
          `/[restaurant]/[orderType]/[store]`,
          `/${cartStore.currentRestaurant.url}/${cartStore.orderType}/${cartStore.currentOutlet.url}`,
          { shallow: true }
        );
    } else {
      router.back();
    }
  };

  const onClick = () => {
    // console.log('ACTION ::', goHome);
    if (action) {
      // console.log('IS ACTION')
      action();
    } else if (goHome == true) {
      // console.log('IS HOME')
      goToHome();
    } else {
      // console.log('IS BACK')
      goBack();
    }
  };

  return (
    <BackButtonWrapper onClick={() => onClick()}>
      <ArrowLeftRound width={12} height={12} />
    </BackButtonWrapper>
  );
});

export default BackButton;
